export const GET_SEGMENT = 'GET_SEGMENT';
export const GET_SEGMENT_ASYNC = 'GET_SEGMENT_ASYNC';
export const SAVE_MONTHLY_UPLOAD = 'SAVE_MONTHLY_UPLOAD';
export const SAVE_MONTHLY_UPLOAD_ASYNC = 'SAVE_MONTHLY_UPLOAD_ASYNC';
export const GET_HISTORICAL = 'GET_HISTORICAL';
export const GET_HISTORICAL_ASYNC = 'GET_HISTORICAL_ASYNC';
export const DOWNLOAD_FILE = 'DOWNLOAD_FILE';
export const DOWNLOAD_FILE_ASYNC = 'DOWNLOAD_FILE_ASYNC';
export const GET_PREVIEW = 'GET_PREVIEW';
export const GET_PREVIEW_ASYNC = 'GET_PREVIEW_ASYNC';
export const CLEAR_SEGMENT = 'CLEAR_SEGMENT';
export const CLEAR_SEGMENT_ASYNC = 'CLEAR_SEGMENT_ASYNC';
export const LOADING = 'LOADING';
export const SEARCH_REPORT = 'SEARCH_REPORT';
export const SEARCH_REPORT_ASYNC = 'SEARCH_REPORT_ASYNC';
export const GET_FINANCIAL_HISTORICAL = 'GET_FINANCIAL_HISTORICAL';
export const GET_FINANCIAL_HISTORICAL_ASYNC = 'GET_FINANCIAL_HISTORICAL_ASYNC';
export const SET_LOADING = 'SET_LOADING';
export const SEARCH_FINANCIAL_HISTORICAL = 'SEARCH_FINANCIAL_HISTORICAL';
export const SEARCH_FINANCIAL_HISTORICAL_ASYNC = 'SEARCH_FINANCIAL_HISTORICAL_ASYNC';
export const EXPORT_FINANCIAL_HISTORICAL = 'EXPORT_FINANCIAL_HISTORICAL';
export const EXPORT_FINANCIAL_HISTORICAL_ASYNC = 'EXPORT_FINANCIAL_HISTORICAL_ASYNC';
export const SAVE_MONTHLY_UPLOAD_SUCCESS = 'SAVE_MONTHLY_UPLOAD_SUCCESS';
export const SAVE_MONTHLY_UPLOAD_FAILURE = 'SAVE_MONTHLY_UPLOAD_FAILURE';
